/* tslint:disable */
/* eslint-disable */
/**
 * passport
 * passport接口文档
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 暂无注释
 * @export
 * @interface AppRequestOauth2TokenRequest
 */
export interface AppRequestOauth2TokenRequest {
    /**
     * 授权方式
     * @type {string}
     * @memberof AppRequestOauth2TokenRequest
     */
    'grant_type': AppRequestOauth2TokenRequestGrantTypeEnum;
    /**
     * 客户端key
     * @type {string}
     * @memberof AppRequestOauth2TokenRequest
     */
    'client_id': string;
    /**
     * 客户端secret
     * @type {string}
     * @memberof AppRequestOauth2TokenRequest
     */
    'client_secret'?: string;
    /**
     * 访问授权码
     * @type {string}
     * @memberof AppRequestOauth2TokenRequest
     */
    'code'?: string;
    /**
     * 用户名
     * @type {string}
     * @memberof AppRequestOauth2TokenRequest
     */
    'username'?: string;
    /**
     * 密码
     * @type {string}
     * @memberof AppRequestOauth2TokenRequest
     */
    'password'?: string;
}

export const AppRequestOauth2TokenRequestGrantTypeEnum = {
    AuthorizationCode: 'authorization_code',
    ClientCredentials: 'client_credentials',
    Password: 'password',
    DingtalkCode: 'dingtalk_code',
    DingtalkCodeAuto: 'dingtalk_code_auto',
    Ldap: 'ldap',
    Sms: 'sms',
    Wechat: 'wechat'
} as const;

export type AppRequestOauth2TokenRequestGrantTypeEnum = typeof AppRequestOauth2TokenRequestGrantTypeEnum[keyof typeof AppRequestOauth2TokenRequestGrantTypeEnum];


